import React from 'react';
import { reduxForm } from 'redux-form';
import {
    useSelector,
    connect as reduxConnect,
} from 'react-redux';
import { withWrapperDisplayName } from '@core/utils/buildDisplayName';
import { IFormHandler } from './form';

/**
 * Контекст, с помощью которого можно определить, что дочерние компоненты находятся внутри формы.
 */
export const ConnectedFormHandlerContext = React.createContext<IFormHandler|object|null>(null);

export type HandlerFormValues<TFormHandler extends IFormHandler<any>> =
    TFormHandler extends IFormHandler<infer TFormValues>
        ? TFormValues
        : never;

export interface WithFormProps<TFormHandler extends IFormHandler<any>> {
    invalid: boolean;
    pristine: boolean;
    initialized: boolean;
    valid: boolean;
    warning: boolean;
    dirty: boolean;
    error?: string;

    initialValues: Partial<HandlerFormValues<TFormHandler>>;
    formSubmitting?: boolean;

    // TODO Check later
    handleSubmit: any;
    // ((e: React.FormEvent) => void) | ((fn: FormSubmitter<TModel>) => () => void);
}

export interface IFormConnectOptions {
    partial: boolean;
}

/**
 * Подключает компонент к {@link simpleFormHandler} или {@link modalFormHandler}.
 *
 * Внутри такого компонента возможно подключение Field компонентов, обернутых с помощью {@link connectToField} или {@link connectToReadField},
 *  которые позволяют получить доступ к конкретным полям в форме для их редактирования.
 *
 * @param Component Компонент, который должен быть подключен к форме
 * @param handler Хедлер для работы с формой
 * @param options Дополнительные настройки поведения формы
 * @returns Возвращает новый компонент, подключенный к форме
 *
 * @example
 * // Оборачиваем компонент и передаем публичные свойства в параметры
 * export default connectToForm<IProps>(function CreateSprintForm(props) {
 *      const { handleSubmit, formSubmitting, error } = props;
 *
 *      return (<>
 *          // Теперь внутри мы можем использовать Field компоненты
 *          <InlineTextInputField name="name" />
 *
 *          <FormAlert alert={error} />
 *          <Button onClick={handleSubmit} text="Создать" />
 *      </>);
 * }, sprintCreateFormHandler);
 */
export default function connectToForm<
    TProps,
    TFormValues
>(
    Component: React.ComponentType<TProps & WithFormProps<IFormHandler<TFormValues>>>,
    handler: IFormHandler<TFormValues>,
    options?: IFormConnectOptions
): React.ComponentType<Exclude<TProps, WithFormProps<IFormHandler<TFormValues>>>> {
    const partialForm = options?.partial === true;

    const FormWrappedComponent = reduxForm({
        ...handler.form,
        destroyOnUnmount: !partialForm,
        forceUnregisterOnUnmount: partialForm,
    })(props => {
        const formSubmitting = useSelector(handler.formSubmitting);
        const error = useSelector(handler.formError);

        return (
            <ConnectedFormHandlerContext.Provider value={handler}>
                {React.createElement(Component, {
                    ...props,
                    error: props.error ?? error,
                    formSubmitting,
                } as any)}
            </ConnectedFormHandlerContext.Provider>
        );
    });

    const WrappedComponent = reduxConnect(undefined, {
        onSubmit: handler.submit,
    })(FormWrappedComponent) as any;

    return withWrapperDisplayName(WrappedComponent, 'connectToForm', Component);
}

