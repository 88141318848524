import React from 'react';
import { Link } from 'react-router-dom';

type TDefaultComponents = {
    Link: React.ElementType<React.AnchorHTMLAttributes<HTMLAnchorElement>>;
}

/**
 * Группа общих компонентов, которая может быть переопределена.
 */
export const DefaultComponents: TDefaultComponents = {
    // Для обратной совместимости используем Link из react-router
    Link: Link as any,
};